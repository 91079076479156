.team {
	padding-top: 2rem;
	padding-bottom: 1.625rem;
	text-align: center;
	background-color:#fafafa;
}

.team h2 {
	margin-bottom: 1rem;
}

@media (min-width: 992px) {
	.team .col-lg-12 div.team-card:nth-child(3n+2) {
		margin-right: 2rem;
		margin-left: 2rem;
	}
}

@media (min-width: 1200px) {
	.team .col-lg-12 div.team-card:nth-child(3n+2) {
		margin-right: 2.875rem;
		margin-left: 2.875rem;
	}
}

.team .team-card {
	max-width: 21rem;
	margin-right: auto;
	margin-bottom: 2rem;
	margin-left: auto;
	padding: 3.25rem 2rem 2rem 2rem;
	border: 1px solid #c4d8dc;
	border-radius: 0.5rem;
	background-color:#f5f8fc;
}

.team .card-image {
	width: 6rem;
	height: 6rem;
	margin-right: auto;
	margin-bottom: 2rem;
	margin-left: auto;
}

.team .card-title {
	margin-bottom: 0.875rem;
}

.team .card-body {
	padding: 0;
}

@media (min-width: 992px) {
	.team .team-card {
		display: inline-block;
		max-width: 17.125rem;
		min-width: 14rem;
		vertical-align: top;
	}
}

@media (min-width: 1200px) {
	.team .team-card {
		max-width: 21rem;
		min-width: 17.125rem;
	}
}