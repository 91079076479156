.quote {
    padding-top: 4rem;
    padding-bottom: 1.625rem;
    text-align: center;
}

.quote .col-lg-6 {
    display:flex;
    align-items:center;
    vertical-align:central;
    justify-content:center;
}

.quote button {
    height: auto;
    padding-top:1em;
    padding-bottom:1em;
    width: auto;
    background-color: #4FBDE4;
    vertical-align:central;
    opacity:90%;
    color: white;
    border-radius: 5px;
    cursor: pointer;
}
