.testimony {
    margin-right: auto;
    margin-bottom: 4.5rem;
    margin-left: auto;
    padding: 3.25rem 2rem 2rem 2rem;
    border: 1px solid #c4d8dc;
    border-radius: 0.5rem;
    background: transparent;
	min-height: 40em;
	box-shadow: 0 0.0625rem 0.375rem 0 rgba(0, 0, 0, 0.1);
	background-color:#f5f8fc;
}

.testimony-content {
    text-align: center;
    margin-top:2rem;
}

.customer-content {
    text-align: center;
    margin-top: 2rem;
}

.testimony-image {
	width: 6rem;
	height: 6rem;
	margin-right: auto;
	margin-bottom: 2rem;
	margin-left: auto;
}

.testimony-title {
    margin-bottom: 0.875rem;
}

.testimony-body {
    padding: 0;
}

@media (min-width: 992px) {
    .testimony {
        display: inline-block;
        max-width: 17.125rem;
        vertical-align: top;
		margin-right: 2rem;
		margin-left: 2rem;
    }
}

@media (min-width: 1200px) {
    .testimony {
        max-width: 21rem;
		margin-right: 2.875rem;
		margin-left: 2.875rem;
		min-height: 38rem;
    }
}