.team-card {
	width: 15rem;
	margin-right: auto;
	margin-bottom: 4.5rem;
	margin-left: auto;
	padding: 3.25rem 2rem 2rem 2rem;
	border: 1px solid #c4d8dc;
	border-radius: 0.5rem;
	min-height:15rem;
	box-shadow: 0 0.0625rem 0.375rem 0 rgba(0, 0, 0, 0.1);
	height:24rem;
}

.team-image {
	width: 6rem;
	height: 6rem;
	margin-right: auto;
	margin-bottom: 2rem;
	margin-left: auto;
	box-shadow: 0 1.3625rem 0.425rem 0 rgba(0, 0, 0, 0.1);
	border-radius:50%
}

.team-title {
	margin-bottom: 0.875rem;
}

.team-body {
	padding: 0;
}

@media (min-width: 992px) {
	.team-card {
		display: inline-block;
		max-width: 17.125rem;
		vertical-align: top;
	}
}

@media (min-width: 1200px) {
	.team-card {
		max-width: 21rem;
	}
}