@media (min-width: 992px) {
	.service .col-lg-12 div.card:nth-child(3n+2) {
		margin-right: 2rem;
		margin-left: 2rem;
	}
}

@media (min-width: 1200px) {
	.service .col-lg-12 div.card:nth-child(3n+2) {
		margin-right: 2.875rem;
		margin-left: 2.875rem;
	}
}

.card-customer {
    margin-right: 1.5em;
    margin-bottom: 4.5rem;
    margin-left: 1.5em;
    padding: 3.25rem 2rem 2rem 2rem;
    border: 1px solid #c4d8dc;
    border-radius: 0.5rem;
    background: transparent;
}

.card-customer-image {
    width: 6rem;
    height: 6rem;
    margin-right: auto;
    margin-bottom: 2rem;
    margin-left: auto;
}

.card-customer-title {
    margin-bottom: 0.875rem;
}

.card-customer-body {
    padding: 0;
}

@media (min-width: 992px) {
    .card-customer {
        display: inline-block;
        max-width: 17.125rem;
        vertical-align: top;
    }
}

@media (min-width: 1200px) {
    .card-customer {
        max-width: 21rem;
    }
}