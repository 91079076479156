.navbar-custom {
	background-color: #fff;
	box-shadow: 0 0.0625rem 0.375rem 0 rgba(0, 0, 0, 0.1);
	font: 600 0.875rem/0.875rem "Raleway", sans-serif;
	transition: all 0.2s;
}

.navbar-custom .navbar-brand.logo-image img {
    width: 4em;
	height: 4em;
}

.navbar-custom .navbar-brand.logo-text {
	font: 600 2rem/1.5rem "Raleway", sans-serif;
	color: #393939;
	text-decoration: none;
}

.navbar-custom .navbar-nav {
	margin-top: 0.75rem;
	margin-bottom: 0.5rem;
}

.navbar-custom .nav-item .nav-link {
	padding: 0.625rem 0.75rem 0.625rem 0.75rem;
	color: #393939;
	text-decoration: none;
	transition: all 0.2s ease;
}
.nav-item-none {
		display:none
}

.navbar-custom .nav-item .nav-link:hover,
.navbar-custom .nav-item .nav-link.active {
	color: #00bfd8;
}

/* Dropdown Menu */
.navbar-custom .dropdown:hover > .dropdown-menu {
	display: block; /* this makes the dropdown menu stay open while hovering it */
	min-width: auto;
	animation: fadeDropdown 0.2s; /* required for the fade animation */
}

@keyframes fadeDropdown {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.navbar-custom .dropdown-toggle:focus { /* removes dropdown outline on focus */
	outline: 0;
}

.navbar-custom .dropdown-menu {
	margin-top: 0;
	border: none;
	border-radius: 0.25rem;
	background-color: #fff;
}

.navbar-custom .dropdown-item {
	color: #393939;
	text-decoration: none;
}

.navbar-custom .dropdown-item:hover {
	background-color: #fff;
}

.navbar-custom .dropdown-item .item-text {
	font: 600 0.875rem/0.875rem "Raleway", sans-serif;
}

.navbar-custom .dropdown-item:hover .item-text {
	color: #00bfd8;
}

.navbar-custom .dropdown-items-divide-hr {
	width: 100%;
	height: 1px;
	margin: 0.75rem auto 0.725rem auto;
	border: none;
	background-color: #c4d8dc;
	opacity: 0.2;
}
/* end of dropdown menu */

.navbar-custom .social-icons {
	display: none;
}

.navbar-custom .navbar-toggler {
	border: none;
	color: #393939;
	font-size: 2rem;
}

.navbar-custom button[aria-expanded='false'] .navbar-toggler-awesome.fas.fa-times{
	display: none;
}

.navbar-custom button[aria-expanded='false'] .navbar-toggler-awesome.fas.fa-bars{
	display: inline-block;
}

.navbar-custom button[aria-expanded='true'] .navbar-toggler-awesome.fas.fa-bars{
	display: none;
}

.navbar-custom button[aria-expanded='true'] .navbar-toggler-awesome.fas.fa-times{
	display: inline-block;
	margin-right: 0.125rem;
}

@media (min-width: 992px) {
	.navbar-custom {
		padding: 2.125rem 1.5rem 2.125rem 2rem;
		box-shadow: none;
        background: transparent;
	}
	
	.navbar-custom .navbar-nav {
		margin-top: 0;
		margin-bottom: 0;
	}
	.nav-item-none {
		display:none
	}
	.navbar-custom .nav-item .nav-link {
		padding: 0.25rem 0.75rem 0.25rem 0.75rem;
		color: #fff;
		opacity: 0.8;
	}
	
	.navbar-custom .nav-item .nav-link:hover,
	.navbar-custom .nav-item .nav-link.active {
		color: #fff;
		opacity: 1;
	}

	.navbar-custom.top-nav-collapse {
        padding: 0.5rem 1.5rem 0.5rem 2rem;
		box-shadow: 0 0.0625rem 0.375rem 0 rgba(0, 0, 0, 0.1);
		background-color: #fff;
	}

	.navbar-custom.top-nav-collapse .nav-item .nav-link {
		color: #393939;
		opacity: 1;
	}
	
	.navbar-custom.top-nav-collapse .nav-item .nav-link:hover,
	.navbar-custom.top-nav-collapse .nav-item .nav-link.active {
		color: #00bfd8;
	}

	.navbar-custom .dropdown-menu {
		padding-top: 1rem;
		padding-bottom: 1rem;
		border-top: 0.75rem solid rgba(0, 0, 0, 0);
		border-radius: 0.25rem;
	}

	.navbar-custom.top-nav-collapse .dropdown-menu {
		border-top: 0.5rem solid rgba(0, 0, 0, 0);
		box-shadow: 0 0.375rem 0.375rem 0 rgba(0, 0, 0, 0.02);
	}

	.navbar-custom .dropdown-item {
		padding-top: 0.25rem;
		padding-bottom: 0.25rem;
	}

	.navbar-custom .dropdown-items-divide-hr {
		width: 84%;
	}

	.navbar-custom .social-icons {
		display: block;
		margin-left: 0.5rem;
	}

	.navbar-custom .fa-stack {
		margin-bottom: 0.1875rem;
		margin-left: 0.25rem;
		font-size: 0.75rem;
	}
	
	.navbar-custom .fa-stack-2x {
		color: #00bfd8;
		transition: all 0.2s ease;
	}
	
	.navbar-custom .fa-stack-1x {
		color: #fff;
		transition: all 0.2s ease;
	}

	.navbar-custom .fa-stack:hover .fa-stack-2x {
		color: #fff;
	}

	.navbar-custom .fa-stack:hover .fa-stack-1x {
		color: #00bfd8;
	}

	.navbar-custom.top-nav-collapse .fa-stack-2x {
		color: #00bfd8;
	}
	
	.navbar-custom.top-nav-collapse .fa-stack-1x {
		color: #fff;
	}

	.navbar-custom.top-nav-collapse .fa-stack:hover .fa-stack-2x {
		color: #00a7bd;
	}
	
	.navbar-custom.top-nav-collapse .fa-stack:hover .fa-stack-1x {
		color: #fff;
	}
}

@media (min-width: 1200px) {
	.navbar-custom {
		padding: 2.125rem 5rem 2.125rem 5rem;
	}

	.navbar-custom.top-nav-collapse {
        padding: 0.5rem 10rem 0.5rem 10rem;
    }
}

.services {
	flex:1,
}