.hero .hero-content {
	padding-top: 2rem;
	padding-bottom: 4rem;
	text-align: center;
	
}

.contentt {
	background-color: #fafafaaa
}

.hero .text-container {
	margin-bottom: 4rem;
}

.hero h1 {
	margin-bottom: 1.125rem;
	font-size: 2.5rem;
	line-height: 3.125rem;
}

.hero .p-large {
	margin-bottom: 1.875rem;
}

.hero .image-container {
	margin-left: 2rem;
	margin-right: 1rem;
	overflow: hidden;
	height: 100%;
	width: 90%;
}

.img-fluide {
	height: inherit;
	width: inherit;
}

.hero ul {
	padding-left: 0;
	list-style: none;
}

.hero ul li{
	margin-bottom: 0.25rem;
	display: flex;
	align-items: flex-start;
}

@media (min-width: 768px) {
	.hero h1 {
		font-size: 3rem;
		line-height: 3.5rem;
    }
}

@media (min-width: 992px) {
	.hero .hero-content {
		text-align: left;
	}

	.hero .text-container {
		margin-top: 3rem;
		margin-bottom: 0;
    }
}

@media (min-width: 1200px) {
	.hero .text-container {
		margin-top: 5.375rem;
		margin-left: 1rem;
		margin-right: 2rem;
	}

	.hero .image-container {
		margin-left: 2rem;
		margin-right: 1rem;
    }
}