.slider {
	padding-top : 2rem;
	padding-bottom : 2rem;
	background-color:#fafafa;
}

.carousel.carousel-slider {
    position: relative;
	width:60%;
    overflow: hidden;
}

.text-slider {
	padding-left:7%;
	display:flex;
	flex-direction:row;
}

.description-container{
	text-align: left!important;
	padding-top:2em;
	
}   

.slider .slide {
	padding-top:5rem;
    margin: auto;
    padding-left: 2rem;
    padding-right: 2rem;
    border: 0px solid #c4d8dc;
    background: transparent;
}

.carousel .slide img {
	width:40% !important;
}

.slider .slide img {
	border-radius:0%;
	width:50%;
}

.slider .slide-title {
    margin-bottom: 0.875rem;
}

.slider .slide-body {
    padding: 0;
}

