.footer {
	position:relative;
	z-index:2;
	padding-top: 3.0rem;
    padding-bottom: 1rem;
    background-color: #1E2B4F;
    color: #000000;
}

.footer .nav-link {
    display: block;
    padding: 0 !important;
}

.footer-none {
	display:none;
}

.logo {
	width: 4em;
	height: 4em;
}

.footer ul.inner-container {
	display: flex;
	margin: auto;
	justify-content: space-between;
}

.footer ul {
	list-style: none;
	margin: 0;
	padding: 0;
}

.footer ul>li.logo-box {
	width: 135px;
	margin: 0 50px 0 0;
	text-align: center;
}

.footer ul>li.logo-box img {
	max-width: 130px;
}

.footer ul>li.logo-box small{
	color: #989fa9;
	display: block;
	font-size: 12px;
	height: 25px;
	line-height: 25px;
	margin: 5px 0;
}

.footer ul>li.social-box {
	width: 250px;
	margin: 0 0 60px 0;
}

.footer ul>li.social-box span {
	margin: 0.5rem 0 0.5rem 0;
}

.footer p{
    color: #000000;
}
.navigation p {
	color: #989fa9;
	text-decoration: none;
	text-transform: uppercase;
	margin: 0.5rem 0 0.5rem 0;
}

.navigation a {
	color: #ffffff;
	text-decoration: none;
}

.navigation a:hover {
	color: #b8e0f1;
}

.footer .footer-col {
	margin-bottom: 2.25rem;
}

.footer h4 {
	margin-bottom: 1rem;
}

.footer .list-unstyled .fas {
	color: #00bfd8;
	font-size: 0.5rem;
	line-height: 1.375rem;
}

.footer .list-unstyled .media-body {
	margin-left: 0.625rem;
}


.footer .fa-stack {
	margin-bottom: 0.75rem;
	margin-right: 0.5rem;
	font-size: 1.5rem;
}

.footer .fa-stack .fa-stack-1x {
    color: #ffffff;
	transition: all 0.2s ease;
}

.footer .fa-stack .fa-stack-2x {
	color: #989fa9;
	transition: all 0.2s ease;
}

.footer .fa-stack:hover .fa-stack-2x {
    color: #33436e;
}

.copyright {
	padding-top: 1rem;
	padding-bottom: 0.375rem;
	text-align: center;
}

.copyright .p-small {
	padding-top: 1.375rem;
	opacity: 0.7;
	color:white;
}

.copyright .p-small a {
	color:#85BEE9;
}