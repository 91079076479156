.biglogo{
	margin-top: 2rem;
	height:100vh;
}
.biglogo img {
	height:60vh;
	width: 35vw;
}
.biglogo .content {
	width: auto;
	background-color: rgb(255 255 255 / 70%);
	border-radius: 1rem;
}

.biglogo .title p{
	color: #1E2B4F;
	font:700 3rem/3.5rem "Raleway", sans-serif;
	overflow-x: auto;
}

.biglogo .description p{
	color: #1E2B4F;
	font: normal 1rem/1.5rem "Raleway", sans-serif;
}


@media (max-width: 992px) {
	.biglogo{
		margin-top: 2rem;
		height:100vh;
	}
	.biglogo img {
		height:50vh;
		width: 80vw;
	}
}

