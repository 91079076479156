	.transition-image {
		margin-left:-56%;
		height:8%;
		width:123%;
		position:fixed;
	}
    .separator {
		height: 15em;
		width: auto;
		margin: 0 0 2em 0;
		margin-bottom: 5em;
	}


