body,
html {
    width: 100%;
	height: 100%;
	
}

body, p {
	color: #1E2B4F; 
	font: 400 0.875rem/1.375rem "Raleway", sans-serif;
	overflow-X:hidden;
}

ul .fas {
	color: #00bfd8;
	margin-right: 1em;
}

.p-large {
	font: 400 1rem/1.5rem "Raleway", sans-serif;
}

.p-small {
	font: 400 0.75rem/1.25rem "Raleway", sans-serif;
}

.p-heading {
	margin-bottom: 3.875rem;
}

.li-space-lg li {
	margin-bottom: 0.25rem;
}

.indent {
	padding-left: 1.25rem;
}

h1 {
	color: #00000;
	font: 700 3rem/3.5rem "Raleway", sans-serif;
}

h2 {
	color: #1E2B4F;
	font: 700 1.3rem/1.7rem "Raleway", sans-serif;
	margin-bottom: 1.375rem;
}

h3 {
	color: #1E2B4F;
	font: 700 1.75rem/2rem "Raleway", sans-serif;
}

h4 {
	color: #1E2B4F;
	font: 700 1.375rem/1.875rem "Raleway", sans-serif;
}

h5 {
	color: #1E2B4F;
	font: 700 1.125rem/1.625rem "Raleway", sans-serif;
}

h6 {
	color: #393939;
	font: 700 1rem/1.5rem "Raleway", sans-serif;
}

a {
	color: #1E2B4F;
	text-decoration: underline;
}

a:hover {
	color: #144A98;
	text-decoration: underline;
}

a.turquoise {
	color: #4FBDE4;
}

a.white {
	color: #fff;
}

.testimonial-text {
	font: italic 400 1rem/1.5rem "Raleway", sans-serif;
}

.testimonial-author {
	font: 700 1rem/1.5rem "Raleway", sans-serif;
}

.turquoise {
	color: #00bfd8;
}

.btn-solid-reg {
	display: inline-block;
	padding: 1.1875rem 2.125rem 1.1875rem 2.125rem;
	border: 0.125rem solid #00bfd8;
	border-radius: 2rem;
	background-color: #00bfd8;
	color: #fff;
	font: 700 0.75rem/0 "Raleway", sans-serif;
	text-decoration: none;
	transition: all 0.2s;
}

.btn-solid-reg:hover {
	background-color: transparent;
	color: #00bfd8;
	text-decoration: none;
}

.btn-solid-lg {
	display: inline-block;
	padding: 1.375rem 2.625rem 1.375rem 2.625rem;
	border: 0.125rem solid #00bfd8;
	border-radius: 2rem;
	background-color: #00bfd8;
	color: #fff;
	font: 700 0.75rem/0 "Raleway", sans-serif;
	text-decoration: none;
	transition: all 0.2s;
}

.btn-solid-lg:hover {
	background-color: transparent;
	color: #00bfd8;
	text-decoration: none;
}

.btn-outline-reg {
	display: inline-block;
	padding: 1.1875rem 2.125rem 1.1875rem 2.125rem;
	border: 0.125rem solid #00bfd8;
	border-radius: 2rem;
	background-color: transparent;
	color: #00bfd8;
	font: 700 0.75rem/0 "Raleway", sans-serif;
	text-decoration: none;
	transition: all 0.2s;
}

.btn-outline-reg:hover {
	background-color: #00bfd8;
	color: #fff;
	text-decoration: none;
}

.btn-outline-lg {
	display: inline-block;
	padding: 1.375rem 2.625rem 1.375rem 2.625rem;
	border: 0.125rem solid #00bfd8;
	border-radius: 2rem;
	background-color: transparent;
	color: #00bfd8;
	font: 700 0.75rem/0 "Raleway", sans-serif;
	text-decoration: none;
	transition: all 0.2s;
}

.btn-outline-lg:hover {
	background-color: #00bfd8;
	color: #fff;
	text-decoration: none;
}

.btn-outline-sm {
	display: inline-block;
	padding: 1rem 1.625rem 0.875rem 1.625rem;
	border: 0.125rem solid #00bfd8;
	border-radius: 2rem;
	background-color: transparent;
	color: #00bfd8;
	font: 700 0.625rem/0 "Raleway", sans-serif;
	text-decoration: none;
	transition: all 0.2s;
}

.btn-outline-sm:hover {
	background-color: #00bfd8;
	color: #fff;
	text-decoration: none;
}

.form-group {
	position: relative;
	margin-bottom: 1.25rem;
}

.form-group.has-error.has-danger {
	margin-bottom: 0.625rem;
}

.form-group.has-error.has-danger .help-block.with-errors ul {
	margin-top: 0.375rem;
}

.label-control {
	position: absolute;
	top: 0.87rem;
	left: 1.375rem;
	color: #626262;
	opacity: 1;
	font: 400 0.875rem/1.375rem "Raleway", sans-serif;
	cursor: text;
	transition: all 0.2s ease;
}

/* IE10+ hack to solve lower label text position compared to the rest of the browsers */
@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {  
	.label-control {
		top: 0.9375rem;
	}
}

.form-control-input:focus + .label-control,
.form-control-input.notEmpty + .label-control,
.form-control-textarea:focus + .label-control,
.form-control-textarea.notEmpty + .label-control {
	top: 0.125rem;
	opacity: 1;
	font-size: 0.75rem;
	font-weight: 700;
}

.form-control-input,
.form-control-select {
	display: block; /* needed for proper display of the label in Firefox, IE, Edge */
	width: 100%;
	padding-top: 1.0625rem;
	padding-bottom: 0.0625rem;
	padding-left: 1.3125rem;
	border: 1px solid #c4d8dc;
	border-radius: 0.25rem;
	background-color: #fff;
	color: #626262;
	font: 400 0.875rem/1.875rem "Raleway", sans-serif;
	transition: all 0.2s;
	-webkit-appearance: none; /* removes inner shadow on form inputs on ios safari */
}

.form-control-select {
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
	height: 3rem;
}

/* IE10+ hack to solve lower label text position compared to the rest of the browsers */
@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {  
	.form-control-input {
		padding-top: 1.25rem;
		padding-bottom: 0.75rem;
		line-height: 1.75rem;
	}

	.form-control-select {
		padding-top: 0.875rem;
		padding-bottom: 0.75rem;
		height: 3.125rem;
		line-height: 2.125rem;
	}
}

select {
    /* you should keep these first rules in place to maintain cross-browser behavior */
    -webkit-appearance: none;
	-moz-appearance: none;
	-ms-appearance: none;
    -o-appearance: none;
    appearance: none;
    background-image: url('../assets/down-arrow.png');
    background-position: 96% 50%;
    background-repeat: no-repeat;
    outline: none;
}

select::-ms-expand {
    display: none; /* removes the ugly default down arrow on select form field in IE11 */
}

.form-control-textarea {
	display: block; /* used to eliminate a bottom gap difference between Chrome and IE/FF */
	width: 100%;
	height: 8rem; /* used instead of html rows to normalize height between Chrome and IE/FF */
	padding-top: 1.25rem;
	padding-left: 1.3125rem;
	border: 1px solid #c4d8dc;
	border-radius: 0.25rem;
	background-color: #fff;
	color: #626262;
	font: 400 0.875rem/1.75rem "Raleway", sans-serif;
	transition: all 0.2s;
}

.form-control-input:focus,
.form-control-select:focus,
.form-control-textarea:focus {
	border: 1px solid #a1a1a1;
	outline: none; /* Removes blue border on focus */
}

.form-control-input:hover,
.form-control-select:hover,
.form-control-textarea:hover {
	border: 1px solid #a1a1a1;
}

.checkbox {
	font: 400 0.75rem/1.25rem "Raleway", sans-serif;
}

input[type='checkbox'] {
	vertical-align: -15%;
	margin-right: 0.375rem;
}

/* IE10+ hack to raise checkbox field position compared to the rest of the browsers */
@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {  
	input[type='checkbox'] {
		vertical-align: -9%;
	}
}

.form-control-submit-button {
	display: inline-block;
	width: 100%;
	height: 3.125rem;
	border: 1px solid #00bfd8;
	border-radius: 1.5rem;
	background-color: #00bfd8;
	color: #fff;
	font: 700 0.75rem/1.75rem "Raleway", sans-serif;
	cursor: pointer;
	transition: all 0.2s;
}

.form-control-submit-button:hover {
	background-color: transparent;
	color: #00bfd8;
}

/* Form Success And Error Message Formatting */
#rmsgSubmit.h3.text-center.tada.animated,
#cmsgSubmit.h3.text-center.tada.animated,
#pmsgSubmit.h3.text-center.tada.animated,
#rmsgSubmit.h3.text-center,
#cmsgSubmit.h3.text-center,
#pmsgSubmit.h3.text-center {
	display: block;
	margin-bottom: 0;
	color: #626262;
	font: 400 1.125rem/1rem "Raleway", sans-serif;
}

.help-block.with-errors .list-unstyled {
	color: #626262;
	font-size: 0.75rem;
	line-height: 1.125rem;
	text-align: left;
}

.help-block.with-errors ul {
	margin-bottom: 0;
}
/* end of form success and error message formatting */

/* Form Success And Error Message Animation - Animate.css */
@-webkit-keyframes tada {
	from {
		-webkit-transform: scale3d(1, 1, 1);
		-ms-transform: scale3d(1, 1, 1);
		transform: scale3d(1, 1, 1);
	}
	10%, 20% {
		-webkit-transform: scale3d(.9, .9, .9) rotate3d(0, 0, 1, -3deg);
		-ms-transform: scale3d(.9, .9, .9) rotate3d(0, 0, 1, -3deg);
		transform: scale3d(.9, .9, .9) rotate3d(0, 0, 1, -3deg);
	}
	30%, 50%, 70%, 90% {
		-webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
		-ms-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
		transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
	}
	40%, 60%, 80% {
		-webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
		-ms-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
		transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
	}
	to {
		-webkit-transform: scale3d(1, 1, 1);
		-ms-transform: scale3d(1, 1, 1);
		transform: scale3d(1, 1, 1);
	}
}

@keyframes tada {
	from {
		-webkit-transform: scale3d(1, 1, 1);
		-ms-transform: scale3d(1, 1, 1);
		transform: scale3d(1, 1, 1);
	}
	10%, 20% {
		-webkit-transform: scale3d(.9, .9, .9) rotate3d(0, 0, 1, -3deg);
		-ms-transform: scale3d(.9, .9, .9) rotate3d(0, 0, 1, -3deg);
		transform: scale3d(.9, .9, .9) rotate3d(0, 0, 1, -3deg);
	}
	30%, 50%, 70%, 90% {
		-webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
		-ms-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
		transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
	}
	40%, 60%, 80% {
		-webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
		-ms-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
		transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
	}
	to {
		-webkit-transform: scale3d(1, 1, 1);
		-ms-transform: scale3d(1, 1, 1);
		transform: scale3d(1, 1, 1);
	}
}

.tada {
	-webkit-animation-name: tada;
	animation-name: tada;
}

.animated {
	-webkit-animation-duration: 1s;
	animation-duration: 1s;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
}
/* end of form success and error message animation - Animate.css */

/* Fade-move Animation For Lightbox - Magnific Popup */
/* at start */
.my-mfp-slide-bottom .zoom-anim-dialog {
	opacity: 0;
	transition: all 0.2s ease-out;
	-webkit-transform: translateY(-1.25rem) perspective(37.5rem) rotateX(10deg);
	-ms-transform: translateY(-1.25rem) perspective(37.5rem) rotateX(10deg);
	transform: translateY(-1.25rem) perspective(37.5rem) rotateX(10deg);
}

/* animate in */
.my-mfp-slide-bottom.mfp-ready .zoom-anim-dialog {
	opacity: 1;
	-webkit-transform: translateY(0) perspective(37.5rem) rotateX(0); 
	-ms-transform: translateY(0) perspective(37.5rem) rotateX(0); 
	transform: translateY(0) perspective(37.5rem) rotateX(0); 
}

/* animate out */
.my-mfp-slide-bottom.mfp-removing .zoom-anim-dialog {
	opacity: 0;
	-webkit-transform: translateY(-0.625rem) perspective(37.5rem) rotateX(10deg); 
	-ms-transform: translateY(-0.625rem) perspective(37.5rem) rotateX(10deg); 
	transform: translateY(-0.625rem) perspective(37.5rem) rotateX(10deg); 
}

/* dark overlay, start state */
.my-mfp-slide-bottom.mfp-bg {
	opacity: 0;
	transition: opacity 0.2s ease-out;
}

/* animate in */
.my-mfp-slide-bottom.mfp-ready.mfp-bg {
	opacity: 0.8;
}
/* animate out */
.my-mfp-slide-bottom.mfp-removing.mfp-bg {
	opacity: 0;
}
/* end of fade-move animation for lightbox - magnific popup */

/* Fade Animation For Image Slider - Magnific Popup */



.page{
	display: flex;
	width: 100%;
	height:auto;
	padding-top: 6rem;
	padding-bottom: 1.625rem;
	text-align: center;
}

.page .page-content{
	flex: 1 1 auto;
	display: flex;
	overflow:hidden;
	justify-content: center;
}

.aspect-ratio img {
	width: auto!important;
}
