.form {
    width: 63rem;
}

.form label {
    width: 100%;
    align-items: baseline;
    text-align: initial;
    margin-top: 1rem;
    margin-bottom: 1rem;
    margin-right: 1rem;

}

.form select option {
    width: 31%;
    text-align: initial;
    margin-top: 1rem;
    margin-bottom: 1rem;
    margin-right: 1rem;
    
}

.input-message {
    width: 92%;
    min-height: 5rem;
    white-space: pre-wrap;
}