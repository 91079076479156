.header .background-particles{
	background-image: url("../assets/header-background-particles.svg");
	background-position: left bottom;
	background-size: cover;
	margin-top: -20%;
	margin-left: 20%;
	position:absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
}